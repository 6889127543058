import React from 'react';
import { Group } from '@mantine/core';
import { AppLogo } from './AppLogo';
import { AppText } from './AppText';

export const AppLogoText = () => {
  // TODO: fix the onClick here
  return (
    <Group gap="xs" onClick={() => null}>
      <AppLogo />
      <AppText />
    </Group>
  );
};
