// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import React from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import type { GatsbyBrowser } from 'gatsby';
import { Provider as JotaiProvider } from 'jotai';
import { WelcomeDialog } from './src/components/WelcomeDialog';
import { Sidebar } from './src/components/navigation';
import { AppContext } from './src/state/AppContext';
import { theme } from './src/theme';
import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

const subreddits = JSON.parse(process.env.GATSBY_SUBREDDITS || '[]');

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return (
    <MantineProvider theme={theme}>
      <JotaiProvider>
        <AppContext.Provider value={{ subreddits }}>
          <Notifications />
          <WelcomeDialog />
          <Sidebar>{element}</Sidebar>
        </AppContext.Provider>
      </JotaiProvider>
    </MantineProvider>
  );
};
