import React, { useContext, useRef, useState } from 'react';
import {
  Combobox,
  Highlight,
  Kbd,
  rem,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { navigate } from 'gatsby';
import { AppContext } from '../../state/AppContext';
import { constants } from '../../utils/config';

export const Search = () => {
  const [search, setSearch] = useState('');
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  // Setup Ctrl + K hot key
  const ref = useRef<HTMLInputElement>(null);
  useHotkeys([
    [
      'mod+K',
      () => {
        if (ref.current) {
          ref.current.focus();
        }
      },
    ],
  ]);

  // Setup data to search over
  const { subreddits } = useContext(AppContext);
  const products = subreddits.flatMap((subreddit) => subreddit.products);

  // TODO: optimize this by pre-computing a single search will all of the needed terms concatenated
  const input = search.toLowerCase();
  let filteredSubreddits = subreddits.filter(
    (subreddit) =>
      subreddit.name.toLowerCase().includes(input) ||
      subreddit.keywords.some((keyword) => keyword.includes(input)),
  );
  let filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(input) ||
      product.keywords.some((keyword) => keyword.includes(input)),
  );

  // Restrict to 7 results
  const max = 7;
  const numSubreddits = filteredSubreddits.length;
  const numProducts = filteredProducts.length;
  const current = numSubreddits + numProducts;
  if (current > max) {
    // If only subreddits
    if (numProducts === 0) {
      filteredSubreddits = filteredSubreddits.slice(0, max);
    }
    // If only products
    else if (numSubreddits === 0) {
      filteredProducts = filteredProducts.slice(0, max);
    }
    // Otherwise aim for an even-ish split leaning towards more subreddits
    else {
      filteredSubreddits = filteredSubreddits.slice(0, 4);
      filteredProducts = filteredProducts.slice(0, 3);
    }
  }

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(value) => {
        // TODO: figure out how to set the search here
        setSearch('');

        combobox.closeDropdown();

        // Have to do a weird hack as gatsby expects a number here
        navigate(value as unknown as number);
      }}
    >
      <Combobox.Target>
        <TextInput
          ref={ref}
          w="50%"
          miw={120}
          maw={560}
          radius="md"
          placeholder={`Search ${constants.appName}`}
          leftSection={
            <IconSearch style={{ width: rem(16), height: rem(16) }} />
          }
          rightSection={<Kbd size="xs">Ctrl + K</Kbd>}
          rightSectionWidth={72}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch('');
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <Combobox.Group label="Communities">
            {filteredSubreddits.map((subreddit) => (
              <Combobox.Option value={`/${subreddit.name}`} key={subreddit.id}>
                <Highlight highlight={search} size="sm">
                  {subreddit.name}
                </Highlight>
              </Combobox.Option>
            ))}
          </Combobox.Group>
          <Combobox.Group label="Products">
            {filteredProducts.map((product) => (
              <Combobox.Option value={`/perma/${product.id}`} key={product.id}>
                <Highlight highlight={search} size="sm">
                  {product.name}
                </Highlight>
              </Combobox.Option>
            ))}
          </Combobox.Group>

          {current === 0 && <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
