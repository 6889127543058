import React, { useCallback, useContext } from 'react';
import {
  Anchor,
  Avatar,
  Center,
  Divider,
  NavLink,
  ScrollArea,
  Text,
} from '@mantine/core';
import {
  IconBook,
  IconBrandReddit,
  IconFileText,
  IconHandStop,
  IconHelp,
  IconHomeFilled,
  IconSearch,
  IconTrendingUp,
  IconX,
} from '@tabler/icons-react';
import { Link } from 'gatsby';
import { useAtomValue } from 'jotai';
import { AppContext } from '../../state/AppContext';
import {
  recentSubredditsAtom,
  useClearRecent,
  useInitializeRecent,
} from '../../state/recent';
import { constants } from '../../utils/config';
import { isBrowser } from '../../utils/environment';
import { convertSubredditsToIdMap } from '../../utils/helpers';
import { iconSize, iconStroke } from '../../utils/icon';

type MenuItem = {
  name: string;
  icon: JSX.Element;
  to: string;
};

const primaryItems = [
  {
    name: 'Home',
    icon: <IconHomeFilled size={iconSize} stroke={iconStroke} />,
    to: '/',
  },
  {
    name: 'Popular',
    icon: <IconTrendingUp size={iconSize} stroke={iconStroke} />,
    to: '/popular',
  },
];

const resources = [
  {
    name: `About ${constants.appName}`,
    icon: <IconBook size={iconSize} stroke={iconStroke} />,
    to: '/about',
  },
  {
    name: `Help`,
    icon: <IconHelp size={iconSize} stroke={iconStroke} />,
    to: '/help',
  },
  {
    name: `Privacy Policy`,
    icon: <IconHandStop size={iconSize} stroke={iconStroke} />,
    to: '/legal/privacy',
  },
  {
    name: `Terms of Service`,
    icon: <IconFileText size={iconSize} stroke={iconStroke} />,
    to: '/legal/terms',
  },
];

export type MenuItemsProps = {
  toggle: () => void;
};

export const MenuItems = ({ toggle }: MenuItemsProps) => {
  // Initialize recent values
  useInitializeRecent();
  const recent = useAtomValue(recentSubredditsAtom);
  const clearRecent = useClearRecent();

  // Get latest subreddit info and store by id
  const { subreddits } = useContext(AppContext);
  const subredditsById = convertSubredditsToIdMap(subreddits);

  const renderItem = useCallback(
    (item: MenuItem) => {
      return (
        <NavLink
          key={item.name}
          label={item.name}
          color="black"
          style={{ borderRadius: '4px' }}
          leftSection={item.icon}
          active={isBrowser ? window.location.pathname === item.to : false}
          onClick={toggle}
          onKeyDown={toggle}
          component={Link}
          to={item.to}
        />
      );
    },
    [toggle],
  );

  // TODO: fix the onClick/onKeyDown here
  return (
    <ScrollArea type="scroll">
      {primaryItems.map((item) => renderItem(item))}
      <NavLink
        label="Explore"
        color="black"
        style={{ borderRadius: '4px' }}
        leftSection={<IconSearch size={iconSize} stroke={iconStroke} />}
        onClick={() => null}
        onKeyDown={() => null}
      />

      <Divider mt="md" mb="md" />

      {recent && recent.length > 0 ? (
        <>
          <NavLink label="Recent" defaultOpened childrenOffset="xs">
            {recent.map((id) => {
              const subreddit = subredditsById[id];
              return renderItem({
                name: subreddit.name,
                icon: (
                  <Avatar
                    src={subreddit.image}
                    name={subreddit.name}
                    color="initials"
                    size={32}
                    alt=""
                    role="presentation"
                  >
                    <IconBrandReddit size={iconSize} stroke={iconStroke} />
                  </Avatar>
                ),
                to: `/${subreddit.name}`,
              });
            })}
            <NavLink
              label="Clear recent"
              color="black"
              style={{ borderRadius: '4px' }}
              leftSection={<IconX size={iconSize} stroke={iconStroke} />}
              onClick={clearRecent}
              onKeyDown={clearRecent}
            />
          </NavLink>
          <Divider mt="md" mb="md" />
        </>
      ) : null}

      <NavLink label="Resources" defaultOpened childrenOffset="xs">
        {resources.map((item) => renderItem(item))}
      </NavLink>

      <Center mt="lg" mb="sm">
        <Anchor
          underline="hover"
          c="black"
          onClick={toggle}
          onKeyDown={toggle}
          component={Link}
          to="/legal/terms"
        >
          <Text size="10px">
            © {constants.companyName} {constants.year}.
          </Text>
        </Anchor>
      </Center>
    </ScrollArea>
  );
};
