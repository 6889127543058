import { Subreddit } from '../types';

export const convertSubredditsToIdMap = (subreddits: Subreddit[]) => {
  const initialValue: Record<string, Subreddit> = {};
  return subreddits.reduce((map, subreddit) => {
    map[subreddit.id] = subreddit;
    return map;
  }, initialValue);
};

export const shuffle = <T>(array: T[]) => {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};
