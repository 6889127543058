import { useCallback, useEffect } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { Subreddit } from '../types';
import { isBrowser } from '../utils/environment';

const recentSubredditKey = 'RecentSubreddits';

export const recentSubredditsAtom = atom<string[]>([]);

export const useInitializeRecent = () => {
  const setRecentSubbreddits = useSetAtom(recentSubredditsAtom);

  useEffect(() => {
    if (isBrowser) {
      const dataString = sessionStorage.getItem(recentSubredditKey) ?? '[]';
      const data = JSON.parse(dataString) as string[];
      setRecentSubbreddits(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useAddRecent = (subreddit: Subreddit) => {
  const [recentSubreddits, setRecentSubreddits] = useAtom(recentSubredditsAtom);

  // If running in the browser, then sessionStorage can be accessed
  if (isBrowser) {
    const index = recentSubreddits.indexOf(subreddit.id);
    if (index === 0) {
      // Do nothing
    } else if (index > 0) {
      // Add to the front and remove others
      const updated = [
        subreddit.id,
        ...recentSubreddits.slice(0, index),
        ...recentSubreddits.slice(index + 1),
      ];
      setRecentSubreddits(updated);
      sessionStorage.setItem(recentSubredditKey, JSON.stringify(updated));
    } else {
      // Add to the front
      const updated = [subreddit.id, ...recentSubreddits];
      setRecentSubreddits(updated);
      sessionStorage.setItem(recentSubredditKey, JSON.stringify(updated));
    }
  }
};

export const useClearRecent = () => {
  const setRecentSubbreddits = useSetAtom(recentSubredditsAtom);

  // If running in the browser, then sessionStorage can be accessed
  return useCallback(() => {
    if (isBrowser) {
      sessionStorage.removeItem(recentSubredditKey);
      setRecentSubbreddits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
