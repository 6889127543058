exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-subreddit-template-tsx": () => import("./../../../src/templates/SubredditTemplate.tsx" /* webpackChunkName: "component---src-templates-subreddit-template-tsx" */)
}

