const isProd = true;

const constants = {
  appName: 'Buyyit',
  appWebsite: 'https://www.redditnowbuyyit.com/',
  companyName: 'Salty Software LLC',
  year: '2024',
};

const flags = {
  // Whether we are running in production
  isProd: isProd,

  // Whether or not to omit logs
  emitLoggerLogs: !isProd,
};

export { flags, constants };
