import React from 'react';
import { Text } from '@mantine/core';
import { constants } from '../../utils/config';

export const AppText = () => {
  return (
    <Text size="xl" fw="bold">
      {constants.appName}
    </Text>
  );
};
