import { createContext } from 'react';
import { Subreddit } from '../types';

export type AppContextProps = {
  subreddits: Subreddit[];
};

export const AppContext = createContext<AppContextProps>({
  subreddits: [],
});
